import React, { Component } from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import DialogPopup from "@Components/DialogPopup";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import BulkIcon from "@material-ui/icons/List";
import { Link } from "react-router-dom";
import Input from "@material-ui/core/Input";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import _ from "lodash";
import { showSnackbar } from "@Actions/snackbarActions";
import {
  initFilters,
  initCurrentFilterId,
  updateCurrentFilterId,
  updateSearchFilterId,
  addFilter,
  removeFilter,
  updateCurrentFilterOriginalData,
  updateSaved,
  revertFilterData,
  updateIsNew,
  updateFilterCount,
} from "@Actions/filterActions";
import UserService from "@Services/User";
import {
  shortenString,
  updatedSelectedFilters,
  getSelectedFilters,
  resetAllScroll,
  isClaimFilter,
} from "@Utils/helpers";
import { getDomains, removeDomains } from "@Actions/domainsActions";
import { getMedia, removeMedia } from "@Actions/mediaActions";
import { getCases, removeCases } from "@Actions/casesActions";
import DomainsService from "@Services/Domains";
import CasesService from "@Services/Cases";
import MediaService from "@Services/Media";
import { getDomainsSearch } from "@Actions/domainsActions";
import { getCasesSearch } from "@Actions/casesActions";
import { getMediaSearch } from "@Actions/mediaActions";
import { Redirect } from "react-router-dom";
import Base64 from "base-64";
import TagInput from "@Components/TagInput";
import actionOptions from "@Configs/bulkActionOptionConfig";
import styles from "./styles";
import {
  getAllCaseTags,
  getAllMediaTags,
  getAllDomainTags,
} from "@Actions/tagsActions";

import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Menu, SvgIcon, TextField, Tooltip } from "@material-ui/core";

class FilterBar extends Component {
  constructor(props) {
    super();
    // this.dropdownRef = React.createRef();
  }

  state = {
    filtersOpen: false,
    isCreating: false,

    openSave: false,
    actionType: "",
    redirect: false,

    popupOpenBulk: false,
    dataList: [],
    data: [],

    activeStep: 0,
    selectToggle: true,

    optionValue: "add_tag",
    optionName: "",
    optionType: null,

    newNote: "",
    selectedTags: [],

    anchorEl: null,
    claimMenuOpen: false,
    numDomainsToClaim: 20,
    claimButtonPressed: false,
  };

  handleOpenFiltersDropdown = (event) => {
    UserService.getFilters()
      .then((response) => {
        this.props.actionInitFilters(response);
      })
      .catch((error) => {
        // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
        console.log(error);
      });

    this.setState({ anchorEl: event.currentTarget });
    // this.dropdownRef.focus();
  };

  handleCloseFiltersDropdown = () => {
    this.setState({ anchorEl: null });
  };

  populateList = () => {
    const { classes } = this.props;
    var filterList = [];

    this.totalFilters = 0;
    _.forEach(
      this.props.filterReducer.filters,
      function (filter, i) {
        if (this.props.filterReducer.currentFilterType === filter.type) {
          this.totalFilters++;
          filterList.push(
            <MenuItem
              className={classes.menuItem}
              key={filter.id}
              selected={
                this.props.filterReducer.currentFilterId === filter.id
                  ? true
                  : false
              }
              onClick={() => this.handleApplyFilterById(filter.id)}
            >
              <ListItemText
                key={filter.id + "__listitemtext"}
                primary={shortenString(filter.name, 35)}
                secondary={
                  filter.resultCount !== "-1" ? filter.resultCount : "..."
                }
                classes={{
                  primary: classes.primary,
                  secondary: classes.secondary,
                }}
              />
            </MenuItem>
          );
        }
      }.bind(this)
    );

    return filterList;
  };

  handleApplyFilterById = (id) => {
    this.handleCloseFiltersDropdown();
    this.props.actionUpdateCurrentFilterId(id);

    this.props.actionShowSnackbar({
      message: this.props.t("_filter_applied_successfully") + "!",
      variant: "success",
    });
    if (this.isSearch()) {
      this.props.actionUpdateSearchFilterId(
        this.props.filterReducer.currentFilterId
      );
    } else {
      updatedSelectedFilters(this.props.filterReducer.currentFilterType, id);
    }

    setTimeout(() => {
      this.updateFilterResults();
    }, 300);
  };

  handleCancelSave = () => {
    this.setState({ openSave: false, actionType: "" });
  };

  isSearch = () => {
    return this.props.navbarReducer.navbar1.link.includes("/search/");
  };

  getApiSearchData = () => {
    if (this.isSearch()) {
      return Base64.encode(
        JSON.stringify({
          class: this.props.searchReducer.search.class.value,
          subclass: this.props.searchReducer.search.subclass.value,
          value: this.props.searchReducer.search.value,
        })
      );
    } else {
      return null;
    }
  };

  updateFilterResults = () => {
    this.props.hideLoading();
    this.props.showLoading();
    resetAllScroll();
    window.scrollTo(0, -300);

    if (this.isSearch()) {
      var searchClassValue = this.props.searchReducer.search.class.value;
      var searchApiParams = this.getApiSearchData();
      if (searchClassValue === "domain") {
        this.props.actionRemoveDomains();
        DomainsService.getDomainsSearch({
          param: searchApiParams,
          pageNum: 1,
          filter: this.props.filterReducer.searchFilterId,
        })
          .then((response) => {
            this.props.actionGetDomainsSearch({ results: response });
            this.props.hideLoading();
          })
          .catch((error) => {
            this.props.actionShowSnackbar({
              message:
                error.message ||
                this.props.t("_something_went_wrong_please_try_again_later"),
              variant: "error",
            });
            console.log(error);
          });
      } else if (searchClassValue === "case") {
        this.props.actionRemoveCases();
        CasesService.getCasesSearch({
          param: searchApiParams,
          pageNum: 1,
          filter: this.props.filterReducer.searchFilterId,
        })
          .then((response) => {
            this.props.actionGetCasesSearch({ results: response });
            this.props.hideLoading();
          })
          .catch((error) => {
            this.props.actionShowSnackbar({
              message:
                error.message ||
                this.props.t("_something_went_wrong_please_try_again_later"),
              variant: "error",
            });
            console.log(error);
          });
      } else {
        this.props.actionRemoveMedia();
        MediaService.getMediaSearch({
          param: searchApiParams,
          pageNum: 1,
          filter: this.props.filterReducer.searchFilterId,
        })
          .then((response) => {
            this.props.actionGetMediaSearch({ results: response });
            this.props.hideLoading();
          })
          .catch((error) => {
            this.props.actionShowSnackbar({
              message:
                error.message ||
                this.props.t("_something_went_wrong_please_try_again_later"),
              variant: "error",
            });
            console.log(error);
          });
      }
    } else {
      if (this.props.filterReducer.currentFilterType === "case") {
        this.props.actionRemoveCases();
        CasesService.getCases({
          pageNum: 1,
          filter: getSelectedFilters()["case"],
        })
          .then((response) => {
            this.props.actionGetCases({ results: response });
            this.props.hideLoading();
            localStorage.setItem("posCases", -150);
          })
          .catch((error) => {
            this.props.actionShowSnackbar({
              message:
                error.message ||
                this.props.t("_something_went_wrong_please_try_again_later"),
              variant: "error",
            });
            console.log(error);
          });
      } else if (this.props.filterReducer.currentFilterType === "domain") {
        this.props.actionRemoveDomains();
        DomainsService.getDomains({
          pageNum: 1,
          filter: getSelectedFilters()["domain"],
        })
          .then((response) => {
            this.props.actionGetDomains({ results: response });
            this.props.actionUpdateFilterCount({
              id: getSelectedFilters()["domain"],
              count: response.total,
            });
            this.props.hideLoading();
            localStorage.setItem("posDomains", -150);
          })
          .catch((error) => {
            this.props.actionShowSnackbar({
              message:
                error.message ||
                this.props.t("_something_went_wrong_please_try_again_later"),
              variant: "error",
            });
            console.log(error);
          });
      } else {
        // media
        this.props.actionRemoveMedia();
        MediaService.getMedia({
          pageNum: 1,
          filter: getSelectedFilters()["media"],
        })
          .then((response) => {
            this.props.actionGetMedia({ results: response });
            this.props.hideLoading();
            localStorage.setItem("posMedia", -150);
          })
          .catch((error) => {
            this.props.actionShowSnackbar({
              message:
                error.message ||
                this.props.t("_something_went_wrong_please_try_again_later"),
              variant: "error",
            });
            console.log(error);
          });
      }
    }
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handlePopupOpenBulk = () => {
    this.setState({
      popupOpenBulk: true,
      selectToggle: true,
      activeStep: 0,
      dataList: [],
      data: [],
      optionValue: "add_tag",
      optionType: null,
      selectedTags: [],
    });

    var arr = [];

    if (this.props.filterReducer.currentFilterType === "domain") {
      arr = _.filter(_.values(this.props.domainsReducer.results));
    } else if (this.props.filterReducer.currentFilterType === "case") {
      arr = _.filter(_.values(this.props.casesReducer.results));
    } else {
      arr = _.filter(_.values(this.props.mediaReducer.results));
    }

    this.setState({ dataList: arr });
  };

  handleClaimOpenClick = (ev) => {
    if (this.props.filterReducer.claims.length === 0)
      this.setState({ claimMenuOpen: true, anchorEl: ev.currentTarget });
  };

  handleClaimMenuClose = (ev) => {
    this.setState({ claimMenuOpen: false });
    this.setState({ anchorEl: null });
  };

  handleNumDomainsToClaimChaned = (ev) => {
    if (/[0-9]*/.test(ev.target.value)) {
      if (ev.target.value && ev.target.value.length > 0)
        this.setState({ numDomainsToClaim: parseInt(ev.target.value) });
      else this.setState({ numDomainsToClaim: null });
    }
  };

  handleClaimNow = () => {
    var fid = undefined;
    this.setState({ claimButtonPressed: true });

    if (
      this.props.filterReducer.currentFilterId !== "0" &&
      !isClaimFilter(this.props.filterReducer.currentFilterId)
    )
      fid = this.props.filterReducer.currentFilterId;

    DomainsService.claimDomains(this.state.numDomainsToClaim, fid)
      .then((payload) => {
        // we have no payload, we must relaod filters and then go to claims
        this.reloadDomains("claim");
      })
      .catch((err) => {
        if (err.response.data.code === 409) {
          this.props.actionShowSnackbar({
            message: this.props.t("_no_claims_possible"),
            variant: "error",
          });
          setTimeout(() => {
            // eslint-disable-next-line
            window.location.href = window.location.href;
          }, 3000);
        } else {
          this.props.actionShowSnackbar({
            message: this.props.t("_claim_failed"),
            variant: "error",
          });
          this.setState({ claimButtonPressed: false });
          this.handleCloseFiltersDropdown();
        }
      });
  };

  checkNumDomainInput = () => {
    if (this.state.numDomainsToClaim) {
      if (
        this.state.numDomainsToClaim <= 0 ||
        this.state.numDomainsToClaim > 100
      )
        return true;
      return false;
    }
    return true;
  };

  handlePopupCloseBulk = () => {
    this.setState({
      popupOpenBulk: false,
      isCreating: false,
      dataList: [],
      selectedTags: [],
    });
  };

  getCurrentPageClass = () => {
    if (this.isSearch()) {
      return this.props.searchReducer.search.class.value;
    } else {
      return this.props.filterReducer.currentFilterType;
    }
  };

  handleUnclaimClicked = () => {
    DomainsService.unclaimDomains()
      .then((pl) => {
        this.reloadDomains("0");
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message:
            error.message ||
            this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);
        this.setState({ claimButtonPressed: false });
        this.handleCloseFiltersDropdown();
      });
  };

  handleNext = () => {
    const stepSelectAction = this.props.t("_select_bulk_action");
    const stepSelectDetails = this.props.t("_bulk_action_details");
    const stepFinalize = this.props.t("_bulk_finalize");
    const steps = [stepSelectAction, stepSelectDetails, stepFinalize];
    if (this.state.activeStep === steps.length - 1) {
      // finish
      this.setState((state) => ({ isCreating: true }));

      var filterId = this.isSearch()
        ? this.props.filterReducer.searchFilterId
        : this.props.filterReducer.currentFilterId;
      filterId = filterId === "0" ? null : filterId;
      var payload = "";
      if (this.state.selectedTags && this.state.selectedTags.length > 0) {
        payload = this.state.selectedTags[0].name;
      }

      var data = {
        data: {
          value: payload,
          f: filterId,
          s: this.getApiSearchData(),
          type: this.state.optionValue,
          no: false,
        },
      };

      if (this.getCurrentPageClass() === "domain") {
        DomainsService.domainsBulkAction(data)
          .then((response) => {
            DomainsService.getAllDomainTags()
              .then((response) => {
                this.props.actionGetAllDomainTags(response);
              })
              .catch((error) => {
                console.log(error);
              });

            this.setState({
              data: [],
              isCreating: false,
              popupOpenBulk: false,
              activeStep: 0,
            });
            this.props.actionShowSnackbar({
              message: this.props.t("_bulk_update_was_successful"),
              variant: "success",
            });
            this.updateFilterResults();
          })
          .catch((error) => {
            this.props.actionShowSnackbar({
              message:
                error.message ||
                this.props.t("_something_went_wrong_please_try_again_later"),
              variant: "error",
            });
            console.log(error);
          });
      } else if (this.getCurrentPageClass() === "case") {
        CasesService.casesBulkAction(data)
          .then((response) => {
            CasesService.getAllCaseTags()
              .then((response) => {
                this.props.actionGetAllCaseTags(response);
              })
              .catch((error) => {
                // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
                console.log(error);
              });

            this.setState({
              data: [],
              isCreating: false,
              popupOpenBulk: false,
              activeStep: 0,
            });
            this.props.actionShowSnackbar({
              message: this.props.t("_bulk_update_was_successful"),
              variant: "success",
            });
            this.updateFilterResults();
          })
          .catch((error) => {
            this.props.actionShowSnackbar({
              message:
                error.message ||
                this.props.t("_something_went_wrong_please_try_again_later"),
              variant: "error",
            });
            console.log(error);
          });
      } else {
        // media
        MediaService.mediaBulkAction(data)
          .then((response) => {
            MediaService.getAllMediaTags()
              .then((response) => {
                this.props.actionGetAllMediaTags(response);
              })
              .catch((error) => {
                // this.props.actionShowSnackbar({ message: this.props.t("_something_went_wrong_please_try_again_later"), variant: "error" });
                console.log(error);
              });

            this.setState({
              data: [],
              isCreating: false,
              popupOpenBulk: false,
              activeStep: 0,
            });
            this.props.actionShowSnackbar({
              message: this.props.t("_bulk_update_was_successful"),
              variant: "success",
            });
            this.updateFilterResults();
          })
          .catch((error) => {
            this.props.actionShowSnackbar({
              message:
                error.message ||
                this.props.t("_something_went_wrong_please_try_again_later"),
              variant: "error",
            });
            console.log(error);
          });
      }

      return;
    }

    console.log(this.state.selectedTags);
    this.setState((state) => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  findOptionName = (type, value) => {
    var name = "";

    for (var i = 0; i < this.currentBulkOptions[type].length; i++) {
      if (this.currentBulkOptions[type][i].value === value) {
        name = this.currentBulkOptions[type][i].name;
      }
    }

    return name;
  };

  populateOptions = (type) => {
    const { classes, t } = this.props;

    var currentOptions = JSON.parse(JSON.stringify(actionOptions));
    if (type === "case" && this.props.casesReducer) {
      // for case bulks we can have additional bulk actions depending on filter.
      switch (this.props.casesReducer["allowedBulkActions"]) {
        case "bulk_move_to_activation_pending":
          currentOptions[type].push({
            value: "move_to_activation_pending",
            display: "move_to_activation_pending",
            name: "_move_to_activation_pending",
          });
          break;
        case "bulk_move_to_active_claim":
          currentOptions[type].push({
            value: "move_to_active_claim",
            display: "move_to_active_claim",
            name: "_move_to_active_claim",
          });
          break;
        default:
          break;
      }
    }

    this.currentBulkOptions = currentOptions;

    return (
      <FormControl component="fieldset" className={classes.optionFormControl}>
        <RadioGroup
          aria-label={type + "Options"}
          name={type + "Options"}
          className={classes.group}
          value={this.state.optionValue}
          onChange={(event) => this.handleOptionChange(event, type)}
        >
          {currentOptions[type].map((option, index) => {
            return (
              <FormControlLabel
                key={index + "_" + Math.random().toString()}
                value={option.value}
                control={<Radio color="secondary" />}
                label={t(option.name)}
                labelPlacement="end"
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  populateOptionsStep = () => {
    return (
      <div>
        {this.props.filterReducer.currentFilterType === "domain" ? (
          <div>{this.populateOptions("domain")}</div>
        ) : this.props.filterReducer.currentFilterType === "case" ? (
          <div>{this.populateOptions("case")}</div>
        ) : (
          <div>{this.populateOptions("media")}</div>
        )}
      </div>
    );
  };

  handleNewNoteChange = (event) => {
    this.setState({
      newNote: event.target.value,
    });
  };

  tagsChanged = (actionName, tag) => {
    console.group("all current Tags");
    // console.log(updatedTags);
    console.log("---action:");
    console.log(actionName);
    console.log("---tag:");
    console.log(tag);
    console.groupEnd();

    if (actionName === "create-option") {
      this.setState({ ...this.state, selectedTags: [tag] });
    } else if (actionName === "remove-option") {
    } else {
      // select-option
      // let newtags = this.state.selectedTags;
      // newtags.push(tag);
      this.setState({ ...this.state, selectedTags: [tag] });
    }

    return null;
  };

  handleOptionChange = (event, type) => {
    var name = this.findOptionName(type, event.target.value);
    this.setState({
      optionValue: event.target.value,
      optionType: type,
      optionName: name,
    });
  };

  populateFinalStep = () => {
    const { classes, t } = this.props;

    if (this.props.tagsReducer == null) {
      return;
    }

    var allTags = [];
    if (this.props.filterReducer.currentFilterType === "case") {
      allTags = this.props.tagsReducer.caseTags;
    } else if (this.props.filterReducer.currentFilterType === "domain") {
      allTags = this.props.tagsReducer.domainTags;
    } else {
      allTags = this.props.tagsReducer.mediaTags;
    }

    if (this.state.optionValue === "add_tag") {
      return (
        <div className={classes.tagInput}>
          <TagInput
            tags={allTags}
            value={this.state.selectedTags}
            onTagsChanged={this.tagsChanged}
            isMulti={false}
            isCreatable={true}
          />
        </div>
      );
    } else if (this.state.optionValue === "remove_tag") {
      return (
        <div className={classes.tagInput}>
          <TagInput
            tags={allTags}
            value={this.state.selectedTags}
            onTagsChanged={this.tagsChanged}
            isMulti={false}
            isCreatable={false}
          />
        </div>
      );
    } else if (this.state.optionValue === "add_note") {
      return (
        <Input
          className={classes.tbAddNote}
          fullWidth
          placeholder={t("_add_notes")}
          value={this.state.newNote}
          id="note"
          onChange={this.handleNewNoteChange}
        />
      );
    } else if (
      (this.state.optionValue === "move_to_active_claim" ||
        this.state.optionValue === "move_to_activation_pending") &&
      this.state.activeStep === 1
    ) {
      this.setState({ activeStep: 2 });
      return;
    } else {
      // actions
      var total = 0;
      if (
        this.props.filterReducer.currentFilterType === "domain" &&
        this.props.domainsReducer != null
      ) {
        total = this.props.domainsReducer.total;
        if (isClaimFilter(this.props.filterReducer.currentFilterId)) {
          total = this.props.domainsReducer.claims.length;
        }
      } else if (
        this.props.filterReducer.currentFilterType === "case" &&
        this.props.casesReducer != null
      ) {
        total = this.props.casesReducer.total;
      } else if (
        this.props.filterReducer.currentFilterType === "media" &&
        this.props.mediaReducer != null
      ) {
        total = this.props.mediaReducer.total;
      } else {
      }
      return (
        <p>
          {t(this.state.optionName)} {t("_for")} {total} {t("_items")}
        </p>
      );
    }
    // return (<p>{this.state.optionValue}</p>);
  };

  getCommaSepratedTags = (tags) => {
    return tags[0] && tags[0].name;
    // var result = "";
    // for(var tag in tags) {
    //   result += tags[tag].name + ", ";
    // }
    // return result;
  };

  populateSummary = () => {
    const { t } = this.props;

    // console.log(this.state.optionValue)
    if (this.state.optionValue === "add_tag") {
      return (
        <p>
          {t("_action")}: {t("_add_tag")} -{" "}
          {this.state.selectedTags[0] && this.state.selectedTags[0].name}
        </p>
      );
    } else if (this.state.optionValue === "remove_tag") {
      return (
        <p>
          {t("_action")}: {t("_remove_tag")} -{" "}
          {this.state.selectedTags[0] && this.state.selectedTags[0].name}
        </p>
      );
    } else if (this.state.optionValue === "add_note") {
      return (
        <p>
          {t("_action")}: {t("_add_note")} - {this.state.newNote}
        </p>
      );
    } else {
      // actions
      return (
        <p>
          {t("_action")}: {t(this.state.optionName)}
        </p>
      );
    }
  };

  reloadDomains(newFilterID) {
    UserService.getFilters()
      .then((data) => {
        this.props.actionInitFilters(data);
        this.props.actionUpdateCurrentFilterId(newFilterID);
        this.props.actionRemoveDomains();
        updatedSelectedFilters(
          this.props.filterReducer.currentFilterType,
          newFilterID
        );
        DomainsService.getDomains({
          pageNum: 1,
          filter: getSelectedFilters()["domain"],
        })
          .then((response) => {
            this.props.actionGetDomains({ results: response });
            this.props.actionUpdateFilterCount({
              id: getSelectedFilters()["domain"],
              count: response.total,
            });
            this.props.hideLoading();
            localStorage.setItem("posDomains", -150);
            this.setState({ claimButtonPressed: false });
            this.setState({ claimMenuOpen: false });
            this.handleCloseFiltersDropdown();
          })
          .catch((error) => {
            this.props.actionShowSnackbar({
              message:
                error.message ||
                this.props.t("_something_went_wrong_please_try_again_later"),
              variant: "error",
            });
            console.log(error);
            this.setState({ claimButtonPressed: false });
            this.handleCloseFiltersDropdown();
          });
      })
      .catch((err) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_failed_load_filters"),
          variant: "error",
        });
        this.setState({ claimButtonPressed: false });
        this.handleCloseFiltersDropdown();
      });
  }

  render() {
    const { classes, filterReducer, t } = this.props;
    const stepSelectAction = this.props.t("_select_bulk_action");
    const stepSelectDetails = this.props.t("_bulk_action_details");
    const stepFinalize = this.props.t("_bulk_finalize");
    const steps = [stepSelectAction, stepSelectDetails, stepFinalize];
    const { activeStep } = this.state;

    if (getSelectedFilters() === null || filterReducer.filters == null) {
      return <></>;
    }

    var total = t("_counting") + "...";
    var claimTotal = null;
    if (
      this.props.filterReducer.currentFilterType === "domain" &&
      this.props.domainsReducer != null
    ) {
      total = this.props.domainsReducer.total;
      claimTotal = this.props.domainsReducer.claimTotal;
    } else if (
      this.props.filterReducer.currentFilterType === "case" &&
      this.props.casesReducer != null
    ) {
      total = this.props.casesReducer.total;
    } else if (
      this.props.filterReducer.currentFilterType === "media" &&
      this.props.mediaReducer != null
    ) {
      total = this.props.mediaReducer.total;
    } else {
    }

    var additio =
      claimTotal !== undefined && claimTotal !== null && claimTotal !== 0
        ? "/" + claimTotal.toString()
        : "";

    var noSelectedFilter =
      (!this.isSearch() &&
        (getSelectedFilters()[filterReducer.currentFilterType] === "0" ||
          typeof filterReducer.filters[
            getSelectedFilters()[filterReducer.currentFilterType]
          ] === "undefined")) ||
      (this.isSearch() &&
        (filterReducer.searchFilterId === null ||
          filterReducer.searchFilterId === "0" ||
          typeof filterReducer.filters[filterReducer.searchFilterId] ===
            "undefined"))
        ? true
        : false;

    return (
      <Paper className={classes.root} elevation={2}>
        <div className={classes.filterBarContainer}>
          <div className={classes.navbarContainer}>
            {this.props.showFilter && (
              <span className={classes.totalBox}>{total + additio}</span>
            )}
            {
              <Link
                className={classes.navbarLink}
                to={this.props.navbarReducer.navbar1.link}
              >
                {this.props.navbarReducer.navbar1.name}
              </Link>
            }
            {this.props.navbarReducer.navbar2 != null ? (
              <span className={classes.seperator}> {">"} </span>
            ) : (
              <></>
            )}
            {this.props.navbarReducer.navbar2 != null ? (
              <Link
                className={classes.navbarLink}
                to={this.props.navbarReducer.navbar2.link}
              >
                {this.props.navbarReducer.navbar2.name}
              </Link>
            ) : (
              <></>
            )}
            {this.props.navbarReducer.navbar3 != null ? (
              <span className={classes.seperator}> {">"} </span>
            ) : (
              <></>
            )}
            {this.props.navbarReducer.navbar3 != null ? (
              <Link
                className={classes.navbarLink}
                to={this.props.navbarReducer.navbar3.link}
              >
                {this.props.navbarReducer.navbar3.name}
              </Link>
            ) : (
              <></>
            )}
          </div>

          {this.props.showFilter ? (
            <div className={classes.selectedFilterContainer}>
              <div className={classes.filterMenuContainer_spaced}>
                {noSelectedFilter && !this.isSearch() ? (
                  <></>
                ) : (
                  ((this.props.filterReducer.currentFilterType === "domain" &&
                    this.props.domainsReducer != null) ||
                    (this.props.filterReducer.currentFilterType === "media" &&
                      this.props.mediaReducer != null) ||
                    (this.props.filterReducer.currentFilterType === "case" &&
                      this.props.casesReducer != null)) && (
                    <div
                      className={classes.bulkAction}
                      onClick={this.handlePopupOpenBulk}
                    >
                      <BulkIcon className={classes.selectedBulkIcon} />
                      <p className={classes.selectedFilterName}>
                        {t("_bulk_action")}
                      </p>
                    </div>
                  )
                )}
                {window.location.href.indexOf("domains") >= 0 && (
                  <>
                    {!isClaimFilter(
                      this.props.filterReducer.currentFilterId
                    ) && (
                      <>
                        <div
                          onClick={this.handleClaimOpenClick}
                          className={
                            this.props.filterReducer.claims.length > 0
                              ? classes.claimClickDisabled
                              : ""
                          }
                          style={{ marginBottom: "2px", marginLeft: 60 }}
                          id="claimOpenClickDiv"
                        >
                          <SvgIcon style={{ verticalAlign: "middle" }}>
                            <path d="M13 3c-4.97 0-9 4.03-9 9H1l4 4 4-4H6c0-3.86 3.14-7 7-7s7 3.14 7 7-3.14 7-7 7c-1.9 0-3.62-.76-4.88-1.99L6.7 18.42C8.32 20.01 10.55 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9m2 8v-1c0-1.1-.9-2-2-2s-2 .9-2 2v1c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1m-1 0h-2v-1c0-.55.45-1 1-1s1 .45 1 1z"></path>
                          </SvgIcon>
                          <Tooltip
                            title={
                              this.props.filterReducer.claims.length > 0
                                ? t("_claim_not_possible")
                                : ""
                            }
                            placement="bottom"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <p className={classes.selectedFilterNameNoPointer}>
                              {t("_claim_menu")}
                            </p>
                          </Tooltip>
                        </div>
                        <Menu
                          style={{ marginTop: "100px" }}
                          open={this.state.claimMenuOpen}
                          onClose={this.handleClaimMenuClose}
                          anchorEl={this.state.anchorEl}
                          disableAutoFocus
                          disableAutoFocusItem
                          disablePortal
                        >
                          <div className={classes.claim_menu}>
                            <div>{t("_claim_description")}</div>
                            <div style={{ marginTop: 10 }}>
                              <p>{t("_num_domains")}</p>
                              <TextField
                                className={classes.claim_input}
                                placeholder={20}
                                value={this.state.numDomainsToClaim}
                                id="numDomainsToClaim"
                                error={this.checkNumDomainInput()}
                                // onKeyDown={this.keyPress}
                                onChange={this.handleNumDomainsToClaimChaned}
                                helperText={
                                  this.checkNumDomainInput()
                                    ? t("_numDomainsErrorText")
                                    : undefined
                                }
                              ></TextField>
                            </div>
                            <div style={{ marginTop: 10 }}>
                              <Button
                                variant="contained"
                                className={classes.button}
                                color="primary"
                                onClick={this.handleClaimNow}
                                disabled={
                                  this.checkNumDomainInput() ||
                                  this.state.claimButtonPressed
                                }
                              >
                                {t("_claim_now")}
                              </Button>
                            </div>
                          </div>
                        </Menu>
                      </>
                    )}
                    {isClaimFilter(this.props.filterReducer.currentFilterId) &&
                      this.props.filterReducer.claims.length > 0 && (
                        <>
                          <div
                            onClick={this.handleUnclaimClicked}
                            style={{
                              marginBottom: "2px",
                              marginLeft: 60,
                              marginRight: 60,
                            }}
                            id="unclaimClickDiv"
                          >
                            <SvgIcon
                              style={{
                                verticalAlign: "middle",
                                transform: "scaleX(-1)",
                              }}
                            >
                              <path d="M13 3c-4.97 0-9 4.03-9 9H1l4 4 4-4H6c0-3.86 3.14-7 7-7s7 3.14 7 7-3.14 7-7 7c-1.9 0-3.62-.76-4.88-1.99L6.7 18.42C8.32 20.01 10.55 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9m2 8v-1c0-1.1-.9-2-2-2s-2 .9-2 2v1c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h4c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1m-1 0h-2v-1c0-.55.45-1 1-1s1 .45 1 1z"></path>
                            </SvgIcon>
                            <p className={classes.selectedFilterNameNoPointer}>
                              {t("_unclaim_all")}
                            </p>
                          </div>
                        </>
                      )}
                    {isClaimFilter(this.props.filterReducer.currentFilterId) &&
                      this.props.filterReducer.claims.length > 0 &&
                      this.props.filterReducer.claims[0].FilterID !== null && (
                        <>
                          <div>
                            {t("_base_filter") +
                              ": " +
                              this.props.filterReducer.filters[
                                this.props.filterReducer.claims[0].FilterID
                              ].name}
                          </div>
                        </>
                      )}
                  </>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        {/* bulk action */}
        <DialogPopup
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.popupOpenBulk}
          onClose={this.handlePopupCloseBulk}
        >
          <DialogTitle>{t("_bulk_action")}</DialogTitle>

          <DialogContent classes={{ root: classes.contentRoot }}>
            <div className={classes.stepperRoot}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => {
                  return (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </div>

            <div
              className={classes.findingRoot}
              style={{ display: activeStep === 0 ? "block" : "none" }}
            >
              {this.populateOptionsStep()}
            </div>

            <div
              className={classes.actionRoot}
              style={{ display: activeStep === 1 ? "block" : "none" }}
            >
              {this.populateFinalStep()}
            </div>

            <div
              className={classes.postRoot}
              style={{ display: activeStep === 2 ? "block" : "none" }}
            >
              <h3>{t("_summary")}</h3>
              <p>
                {t("_total_items")}: {total}
              </p>
              {this.populateSummary()}
            </div>

            <LinearProgress
              className={classes.createProgress}
              color="secondary"
              style={{
                visibility: this.state.isCreating ? "visible" : "hidden",
              }}
            />
          </DialogContent>

          <DialogActions>
            <Button
              onClick={this.handlePopupCloseBulk}
              color="secondary"
              disabled={this.state.isCreating ? true : false}
            >
              {t("_cancel")}
            </Button>

            <div>
              <Button
                onClick={this.handleBack}
                className={classes.backButton}
                disabled={
                  this.state.isCreating || activeStep === 0 ? true : false
                }
              >
                {t("_back")}
              </Button>
              <Button
                color="primary"
                onClick={this.handleNext}
                disabled={
                  this.state.isCreating ||
                  (activeStep === 1 &&
                    this.state.selectedTags.length < 1 &&
                    this.state.optionValue !== "move_to_active_claim" &&
                    this.state.optionValue !== "move_to_activation_pending" &&
                    this.state.optionValue !== "delete_pictures_and_results" &&
                    this.state.optionValue !== "remove_picture_from_search" &&
                    this.state.optionValue !== "add_picture_to_the_search")
                    ? true
                    : false
                }
              >
                {activeStep === steps.length - 1 ? t("_finish") : t("_next")}
              </Button>
            </div>
          </DialogActions>
        </DialogPopup>

        {this.state.redirect ? (
          <Redirect
            push={true}
            to={
              "/search/" +
              Base64.encode(JSON.stringify(this.props.searchReducer))
            }
          />
        ) : (
          ""
        )}
      </Paper>
    );
  }
}

FilterBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    filterReducer: state.filterReducer,
    domainsReducer: state.domainsReducer,
    mediaReducer: state.mediaReducer,
    casesReducer: state.casesReducer,
    navbarReducer: state.navbarReducer,
    searchReducer: state.searchReducer,
    tagsReducer: state.tagsReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actionInitFilters: initFilters,
      actionInitCurrentFilterId: initCurrentFilterId,
      actionUpdateCurrentFilterId: updateCurrentFilterId,
      actionAddFilter: addFilter,
      actionRemoveFilter: removeFilter,
      actionUpdateCurrentFilterOriginalData: updateCurrentFilterOriginalData,
      actionUpdateSaved: updateSaved,
      actionRevertFilterData: revertFilterData,
      actionUpdateIsNew: updateIsNew,
      actionUpdateSearchFilterId: updateSearchFilterId,
      actionUpdateFilterCount: updateFilterCount,

      actionGetDomainsSearch: getDomainsSearch,
      actionGetCasesSearch: getCasesSearch,
      actionGetMediaSearch: getMediaSearch,

      actionGetAllDomainTags: getAllDomainTags,
      actionGetAllCaseTags: getAllCaseTags,
      actionGetAllMediaTags: getAllMediaTags,

      actionGetDomains: getDomains,
      actionRemoveDomains: removeDomains,

      actionGetMedia: getMedia,
      actionRemoveMedia: removeMedia,

      actionGetCases: getCases,
      actionRemoveCases: removeCases,
      actionShowSnackbar: showSnackbar,
      showLoading,
      hideLoading,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(translate("translations")(withStyles(styles)(FilterBar)));
