import React, { Component } from "react";
import DomainCard from "@Components/cards/DomainCard";
import ScrollButton from "@Components/ScrollButton";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { setNavbar1, setNavbar2, setNavbar3 } from "@Actions/navbarActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { showLoading, hideLoading } from "react-redux-loading-bar";
import _, { bind } from "lodash";
import InfiniteScroll from "react-infinite-scroller";
import LinearProgress from "@material-ui/core/LinearProgress";
import { setToolbar } from "@Actions/toolbarActions";
import DomainsService from "@Services/Domains";
import {
  getDomains,
  removeDomains,
  getDomainsMore,
} from "@Actions/domainsActions";
import {
  initCurrentFilterType,
  updateCurrentFilterId,
  updateFilterCount,
} from "@Actions/filterActions";
import { showSnackbar } from "@Actions/snackbarActions";
import LoadingBg from "@Components/LoadingBg";
import { getSelectedFilters, resetAllScroll } from "@Utils/helpers";

import { removeSearch } from "@Actions/searchActions";

import styles from "./styles";

class Domains extends Component {
  constructor(props) {
    super(props);
    props.actionRemoveSearch();
    props.hideLoading();
    props.showLoading();
    props.setToolbar(0);
    props.actionInitCurrentFilterType("domain");

    setTimeout(() => {
      this.props.actionUpdateCurrentFilterId(getSelectedFilters()["domain"]);
    }, 1000);

    props.setNavbar1({ name: this.props.t("_domains"), link: "/domains?r" });
    props.setNavbar2(null);
    props.setNavbar3(null);

    bind(this.populateDomains);
  }

  state = {
    lbgMessage: "",
    lbgAnimated: true,
    isLoading: true,
    hasMore: false,
    isScrollReseted: false,
    detailsOpenedFor: undefined,
    lastPage: null,
  };

  startHasMoreTimer = () => {
    const that = this;
    setTimeout(() => {
      that.setState({ hasMore: true });
    }, 5000);
  };

  componentDidMount() {
    if (
      this.props.domainsReducer == null ||
      this.props.location.search === "?r" ||
      (this.props.domainsReducer == null &&
        this.props.searchReducer.search.class == null)
    ) {
      this.props.actionRemoveDomains();
      resetAllScroll();
      window.scrollTo(0, -300);
      DomainsService.getDomains({
        pageNum: 1,
        filter: getSelectedFilters()["domain"],
      })
        .then((response) => {
          this.props.actionGetDomains({ results: response });
          this.props.actionUpdateFilterCount({
            id: getSelectedFilters()["domain"],
            count: response.total,
          });

          this.props.hideLoading();
          this.startHasMoreTimer();
          this.setState({ isLoading: false });
        })
        .catch((error) => {
          this.startHasMoreTimer();
          this.setState({ lbgAnimated: false, lbgMessage: error.message });
          this.props.actionShowSnackbar({
            message: error.message,
            variant: "error",
          });
          this.props.hideLoading();
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ hasMore: true });
      this.props.hideLoading();
    }

    setTimeout(() => {
      window.scrollTo(0, parseInt(localStorage.getItem("posDomains")));
    }, 1000);
  }

  componentWillUnmount() {
    localStorage.setItem("posDomains", window.pageYOffset);
  }

  loadMore = (page) => {
    if (!this.props.domainsReducer || !this.props.domainsReducer.results)
      return;
    page = Object.keys(this.props.domainsReducer.results).length / 20 + 1;
    if (this.state.lastPage && this.state.lastPage === page) page = page + 1;
    if (
      this.props.domainsReducer == null ||
      this.props.domainsReducer.results === {} ||
      Object.keys(this.props.domainsReducer).length === 0
    ) {
      if (this.state.isScrollReseted === false) {
        this.setState({ hasMore: true, lastPage: null });
      }
    }

    if (this.state.isLoading) {
      return;
    }

    this.setState({
      lastPage: page,
      hasMore: false,
      isLoading: true,
      isScrollReseted: false,
    });

    DomainsService.getDomains({
      pageNum: page,
      filter: getSelectedFilters()["domain"],
    })
      .then((response) => {
        console.log(
          "reducer has " +
            Object.keys(response.results).length +
            " items. load more"
        );
        this.props.actionGetDomainsMore({ results: response });
        this.startHasMoreTimer();
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.props.actionShowSnackbar({
          message: this.props.t("_something_went_wrong_please_try_again_later"),
          variant: "error",
        });
        console.log(error);

        this.startHasMoreTimer();
        this.setState({ isLoading: false });
      });
  };

  // componentDidUpdate = () => {
  //   setTimeout(() => {
  //     if(!this.setState.hasMore)
  //     this.setState({ hasMore: true });
  //   }, 1000);
  // }

  handleDetailsOpened = (domainId) => {
    this.setState({ detailsOpenedFor: domainId });
  };

  populateDomains() {
    var list = [];
    var that = this;

    _.forOwn(this.props.domainsReducer.results, function (value, key) {
      list.push(
        <DomainCard
          detailsOpen={
            that.props.domainsReducer.results &&
            key === that.state.detailsOpenedFor
          }
          onDetailsOpened={that.handleDetailsOpened}
          domainId={key}
          key={key}
          page="domains"
        />
      );
    });

    return list;
  }

  render() {
    const { classes } = this.props;

    if (localStorage.getItem("findingDeleted") === "1") {
      this.props.actionShowSnackbar({
        message:
          "There was an update on findings, therfore the list is refreshed.",
        variant: "info",
      });

      localStorage.setItem("findingDeleted", "0");
    }

    if (
      this.props.domainsReducer == null ||
      this.props.domainsReducer.results === {} ||
      Object.keys(this.props.domainsReducer).length === 0
    ) {
      if (this.state.isScrollReseted === false) {
        // this.setState({ hasMore: true, isScrollReseted: true });
      }
      return (
        <div className={classes.root}>
          <LoadingBg
            pageType="domains"
            message={this.state.lbgMessage}
            animated={this.state.lbgAnimated}
          />
        </div>
      );
    } else if (
      this.props.domainsReducer &&
      this.props.domainsReducer.total === 0
    ) {
      return (
        <div className={classes.root}>
          <LoadingBg
            pageType="domains"
            message={this.props.t("_no_data_found")}
            animated={false}
          />
        </div>
      );
    } else {
    }

    // alert(Math.floor(Object.keys(this.props.domainsReducer.results).length / 20))
    return (
      <div className={classes.root}>
        <InfiniteScroll
          pageStart={
            this.props.domainsReducer.results
              ? Math.floor(
                  Object.keys(this.props.domainsReducer.results).length / 20
                )
              : 0
          }
          loadMore={this.loadMore}
          hasMore={this.state.hasMore}
          loader={
            <div className={classes.loader} key={0}>
              <LinearProgress color="secondary" />
            </div>
          }
        >
          {this.populateDomains()}
        </InfiniteScroll>
        {!this.state.hasMore && this.state.isLoading && (
          <div className={classes.loader} key={1}>
            <LinearProgress color="secondary" />
          </div>
        )}
        <ScrollButton></ScrollButton>
      </div>
    );
  }
}

Domains.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    domainsReducer: state.domainsReducer,
    filterReducer: state.filterReducer,
    searchReducer: state.searchReducer,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showLoading,
      hideLoading,
      setToolbar,
      setNavbar1,
      setNavbar2,
      setNavbar3,

      actionRemoveSearch: removeSearch,

      actionGetDomains: getDomains,
      actionGetDomainsMore: getDomainsMore,
      actionRemoveDomains: removeDomains,
      actionInitCurrentFilterType: initCurrentFilterType,
      actionUpdateCurrentFilterId: updateCurrentFilterId,
      actionShowSnackbar: showSnackbar,
      actionUpdateFilterCount: updateFilterCount,
    },
    dispatch
  );
}

export default withStyles(styles)(
  translate("translations")(
    connect(mapStateToProps, matchDispatchToProps)(Domains)
  )
);
